import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const events = [
  {
    id: 1,
    title: "Iftar de bienfaisance",
    shortDesc:
      "Un repas spécial ramadan. Rejoignez-nous pour un moment convivial et spirituel le vendredi 7 mars à 16h30 à Marseille (3 rue de la Caserne, 13003).",
    longDesc:
      "Au programme :\n📖 Récitations du Noble Coran\n🧠 Quiz interactif\n🗣️ Khutba inspirante sur le mois béni\n🤲 Taraweeh\n\nUne belle occasion de rompre le jeûne ensemble tout en soutenant une noble cause, car tous les bénéfices seront reversés à l’école l’Olivier. 💚\n\n📍 Tarifs :\n👤 Adulte : 20€\n👶 Enfant : 10€",
    date: "15 Mars 2025",
    imageDesktop: "/assets/images/iftar-desktop.webp",
    imageMobile: "/assets/images/iftar.webp",
  },
  {
    id: 2,
    title: "Radio scolaire",
    // shortDesc: "Une célébration festive avec les performances des élèves.",
    shortDesc:
      "Tous les matins sur Instagram, retrouvez nos élèves pour une radio scolaire où ils récitent ensemble de belles invocations",
    date: "20 Juin 2025",
    imageDesktop: "/assets/images/radio-scolaire-desktop.webp",
    imageMobile: "/assets/images/radio-scolaire.webp",
  },
];

export const Evenement = () => {
  const [expandedEvent, setExpandedEvent] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 720);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white text-black">
      {/* Header */}
      <header
        className="relative h-[50vh] bg-cover"
        style={{
          backgroundImage: "url('/assets/images/kids-fun.webp')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <motion.div
          className="absolute inset-0 flex flex-col gap-6 justify-center items-center text-white text-center p-4"
          initial={{ opacity: 0, y: 50 }} // Définir l'initialisation de l'élément
          whileInView={{ opacity: 1, y: 0 }} // L'élément devient complètement visible et revient à sa position d'origine
          transition={{ duration: 1 }} // Durée de l'animation
          viewport={{ once: true }} // L'animation ne se déclenche qu'une seule fois lorsque l'élément est visible
        >
          <h1 className="text-4xl sm:text-5xl">Évenements de l'école</h1>
          <p className="text-xl italic !text-center">
            Découvrez nos évenements extra-scolaire avec l'école l'Olivier
          </p>
        </motion.div>
      </header>

      {/* Liste des événements */}
      <section className="sm:w-3/4 sm:mx-auto flex flex-col md:flex-row md:flex-wrap justify-center items-center md:items-stretch py-10 px-4 gap-6">
        {events.map((event) => (
          <motion.article
            key={event.id}
            className="bg-[#e9ebc4] p-6 rounded-2xl shadow-lg border border-[#8f9324] overflow-hidden transition-transform transform hover:scale-105 max-w-96 max-h-[700px]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={isMobile ? event.imageMobile : event.imageDesktop}
              alt={event.title}
              className="w-full h-80 object-cover rounded-xl border border-[#8f9324]"
            />
            <div className="mt-4">
              <h2 className="text-2xl font-semibold text-[#8f9324] mb-2">
                {event.title}
              </h2>
              <motion.div
                className="text-[#848660] my-5 whitespace-pre-line"
                initial={{ maxHeight: 100 }}
                animate={{ maxHeight: expandedEvent === event.id ? 300 : 100 }}
                transition={{ duration: 0.3 }}
              >
                {expandedEvent === event.id ? event.longDesc : event.shortDesc}
              </motion.div>
              <p className="text-sm text-gray-500 mt-2">📅 {event.date}</p>
            </div>
          </motion.article>
        ))}
      </section>
    </div>
  );
};
