import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { FaHome, FaBus, FaPencilAlt } from "react-icons/fa";

export const NousSoutenir = () => {
  const aides = [
    {
      icon: <FaPencilAlt />,
      title: "Matériel",
      description: "Financer du matériel pédagogique de qualité.",
    },
    {
      icon: <FaBus />,
      title: "Sorties",
      description: "Organiser des sorties éducatives.",
    },
    {
      icon: <FaHome />,
      title: "Cadre",
      description: "Maintenir nos locaux dans les meilleures conditions.",
    },
  ];

  useEffect(() => {
    document.title = "Nous Soutenir - École L'Olivier";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Soutenez l'École L'Olivier pour offrir un cadre éducatif de qualité et des ressources pédagogiques pour nos élèves."
      );
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content =
        "Soutenez l'École L'Olivier pour offrir un cadre éducatif de qualité et des ressources pédagogiques pour nos élèves.";
      document.head.appendChild(metaTag);
    }
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col">
        {/* En-tête avec image de fond agrandie */}
        <header
          className="relative h-80 md:h-96 flex items-center justify-center bg-cover bg-center"
          style={{
            backgroundImage: "url('/assets/images/imgcour-ecole.webp')",
            backgroundColor: "#8f9324", // Couleur de fallback
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="flex flex-col justify-center items-center z-10">
            <h1 className="text-4xl md:text-5xl font-bold text-white relative z-10 text-center px-4 mb-5">
              Soutenez l'École L'Olivier
            </h1>
            <p className="font-bold text-white relative z-10 text-center max-w-96 flex flex-col">
              <span className="mb-2 text-xl">
                قال رسول الله صلى الله عليه و سلم
              </span>
              <span className="text-xl">
                «الصدقة تطفئ الخطيئة كما يطفئ الماء النار»
              </span>
            </p>
            {/* Bouton de cagnotte */}
            <div className="mt-5">
              <a
                href="https://www.cotizup.com/association-olivier" // Remplacez par le vrai lien
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-8 py-3 text-lg font-medium rounded-lg shadow-lg hover:shadow-xl transition-all z-auto"
                style={{
                  backgroundColor: "#8f9324",
                  color: "#e9ebc4",
                }}
              >
                Soutenez l'école
              </a>
            </div>
          </div>
        </header>

        {/* Contenu principal */}
        <main className="flex-grow p-6 md:p-12 w-3/4 mx-auto">
          <section className="mb-12">
            <h2
              className="text-2xl text-center md:text-3xl font-semibold mb-6"
              style={{ color: "#8f9324" }}
            >
              Pourquoi nous soutenir ?
            </h2>
            <div className="space-y-4 text-gray-700">
              <p className="text-center">
                L'École L'Olivier est un lieu d'éducation bienveillante et
                innovante, engagée à offrir un environnement enrichissant pour
                nos élèves. Votre soutien financier nous permet de :
              </p>

              <motion.section
                className="py-10"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }}
              >
                <div className="relative flex flex-wrap justify-center gap-10 w-full">
                  {aides.map((aide, index) => (
                    <motion.div
                      key={index}
                      className="relative bg-white shadow-lg p-6 rounded-2xl w-60 text-center flex flex-col items-center border-4 border-[#BABC79]"
                      initial={{ opacity: 0, y: 20 }} // Initial position (invisible and slightly below)
                      whileInView={{ opacity: 1, y: 0 }} // Becomes visible and moves to original position
                      transition={{ duration: 0.6, delay: index * 0.2 }} // Stagger the animations
                    >
                      <div className="text-[#8F9324] text-4xl">{aide.icon}</div>
                      <h3 className="font-bold text-lg mt-3 mb-2 text-[#848660]">
                        {aide.title}
                      </h3>
                      <span className="text-[#848660]">{aide.description}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.section>

              {/* Vidéo YouTube */}
              <div className="flex justify-center mt-12">
                <div className="w-full max-w-2xl">
                  <div
                    className="relative"
                    style={{ paddingBottom: "56.25%", height: 0 }}
                  >
                    <iframe
                      className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
                      src="https://www.youtube.com/embed/CD2uIg55ctk"
                      title="Présentation de l'École L'Olivier"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>

              <p className="text-center">
                Chaque contribution, petite ou grande, fait la différence. Merci
                pour votre générosité !
              </p>
            </div>
          </section>

          {/* Bouton de cagnotte */}
          <div className="text-center">
            <a
              href="https://www.cotizup.com/association-olivier" // Remplacez par le vrai lien
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-8 py-3 text-lg font-medium rounded-lg shadow-lg hover:shadow-xl transition-all"
              style={{
                backgroundColor: "#8f9324",
                color: "#e9ebc4",
              }}
            >
              Participer à la cagnotte
            </a>
          </div>
        </main>
      </div>
    </>
  );
};
