import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

export function Faq() {
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <Accordion
        open={open === 1}
        className="mb-2 rounded-lg border-2 border-[#8F9324] px-4"
      >
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className={`border-b-0 transition-colors ${
            open === 1
              ? "text-[#8F9324] hover:text-[#848660]"
              : "text-[#C6C992] hover:text-[#8F9324]"
          }`}
        >
          Comment inscrire mon enfant à l’école ?{" "}
        </AccordionHeader>
        <AccordionBody className="pt-0 text-base font-bold">
          Pour inscrire votre enfant, veuillez envoyer un e-mail sur la page{" "}
          <Link to="/contact" className=" font-extrabold underline">
            contact
          </Link>{" "}
          en précisant vos motivations, ainsi que les informations suivantes :
          nom et prénom des parents, nom et prénom de l’enfant, et sa date de
          naissance. Retrouvez toutes les modalites d'inscription sur la page{" "}
          <Link
            to="/modalites-inscription"
            className=" font-extrabold underline"
          >
            inscription
          </Link>{" "}
        </AccordionBody>
      </Accordion>

      <Accordion
        open={open === 2}
        className="mb-2 rounded-lg border-2 border-[#8F9324] px-4"
      >
        <AccordionHeader
          onClick={() => handleOpen(2)}
          className={`border-b-0 transition-colors ${
            open === 2
              ? "text-[#8F9324] hover:text-[#848660]"
              : "text-[#C6C992] hover:text-[#8F9324]"
          }`}
        >
          À quelle période ont lieu les inscriptions ?{" "}
        </AccordionHeader>
        <AccordionBody className="pt-0 text-base font-bold">
          Les inscriptions pour l’entrée en petite section ouvrent en janvier.
          Pour les classes de la moyenne section au CM2, les inscriptions
          ouvrent en <strong>avril.</strong>
        </AccordionBody>
      </Accordion>

      <Accordion
        open={open === 3}
        className="mb-2 rounded-lg border-2 border-[#8F9324] px-4"
      >
        <AccordionHeader
          onClick={() => handleOpen(3)}
          className={`border-b-0 transition-colors ${
            open === 3
              ? "text-[#8F9324] hover:text-[#848660]"
              : "text-[#C6C992] hover:text-[#8F9324]"
          }`}
        >
          Quels sont les frais de scolarité ?
        </AccordionHeader>
        <AccordionBody className="pt-0 text-base font-bold">
          Les frais de scolarité sont consultables directement sur notre site
          internet, dans la rubrique{" "}
          <Link
            to="/modalites-inscription"
            className=" font-extrabold underline"
          >
            {" "}
            inscription
          </Link>
        </AccordionBody>
      </Accordion>

      <Accordion
        open={open === 4}
        className="rounded-lg border-2 border-[#8F9324] px-4"
      >
        <AccordionHeader
          onClick={() => handleOpen(4)}
          className={`border-b-0 transition-colors ${
            open === 4
              ? "text-[#8F9324] hover:text-[#848660]"
              : "text-[#C6C992] hover:text-[#8F9324]"
          }`}
        >
          Quel programme éducatif suit l’école L’Olivier ?{" "}
        </AccordionHeader>
        <AccordionBody className="pt-0 text-base font-bold">
          L’école L’Olivier suit un programme conforme aux exigences de
          l’éducation nationale tout en intégrant des valeurs musulmanes.
        </AccordionBody>
      </Accordion>
    </>
  );
}
