import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export const ButtonTop = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  
    const ViewButtonTop = () => {
      const scrollThreshold = 300; // Hauteur minimale pour afficher le bouton
      const scrolled = window.scrollY; // Position actuelle de scroll
      setIsVisible(scrolled > scrollThreshold); // Afficher ou masquer le bouton
    };
  
    useEffect(() => {
      window.addEventListener("scroll", ViewButtonTop);
      return () => {
        window.removeEventListener("scroll", ViewButtonTop); // Nettoyer l'écouteur
      };
    }, []);
  
    return (
      <>
        <button
          onClick={scrollToTop}
          className={`w-16 h-16 bg-vertLogo rounded-full fixed bottom-10 md:bottom-20 right-10 grid place-items-center transition-all duration-300 ease-linear z-[99] ${
            isVisible ? "scale-100 opacity-100" : "scale-0 opacity-0"
          }`}
          aria-label="bouton pour remonter en haut du site"
        >
          <FontAwesomeIcon icon={faArrowUp} className=" text-white" />
        </button>
      </>
    );
  }
