import {React} from "react";
import { CardInfo } from "../../composants/cards/CardInfo";
import { Gallerie } from "../../composants/gallerie/Galerie";


export const InfoEcole = () => {

  
  const infosEcole = {
    eleves: {
      nbr: 375,
      mess: "Élèves au sein de l'école",
    },
    enseignants: {
      nbr: 22,
      mess: "Enseignants du socle commun",
    },
    enseignantsIslam: {
      nbr: 5,
      mess: "Enseignants de la culture religieuse musulmane",
    },
    classes: {
      nbr: 16,
      mess: "Classes au sein de l'école",
    },
  };
  return (
    <>
      <main className="infoEcole w-full h-full py-10">
        <h2 className=" text-3xl lg:text-4xl text-center pb-[40px]">
          L'École l'Olivier c'est
        </h2>
        <section className="container-infos flex flex-wrap items-center gap-6 justify-evenly my-10">
        
            <CardInfo
         
              nbr={infosEcole.enseignants.nbr}
              mess={infosEcole.enseignants.mess}
              color={"#FD938B"}
            />

            <CardInfo
              nbr={infosEcole.eleves.nbr}
              mess={infosEcole.eleves.mess}
              color={"#AFA3CE"}
            />

          <CardInfo
            nbr={infosEcole.enseignantsIslam.nbr}
            mess={infosEcole.enseignantsIslam.mess}
            color={"#4AA3A2"}
          />

          <CardInfo
            nbr={infosEcole.classes.nbr}
            mess={infosEcole.classes.mess}
            color={"#A1A27E"}
          />

        </section>

          <section className="galeries mx-auto grid justify-evenly my-10">
            <Gallerie />
          </section>
   
      </main>
    </>
  );
};
