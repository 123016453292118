import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaLightbulb,
  FaHandsHelping,
  FaBookOpen,
  FaUsers,
  FaMosque,
  FaGlobe,
} from "react-icons/fa";
import { CardHistory } from "../../../composants/cards/CardHistory";
import "./presentation.css";

export const Presentation = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = "Notre Histoire - École Primaire l'Olivier";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Découvrez l'histoire de l'école musulman l'Olivier, ses origines et son évolution."
      );
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content =
        "Découvrez l'histoire de l'école l'Olivier, ses origines et son évolution.";
      document.head.appendChild(metaTag);
    }
  }, [location]);

  const scrollToNextSection = () => {
    // Remplace `next-section-id` par l'ID de la section suivante
    const nextSection = document.getElementById("historique");
    nextSection?.scrollIntoView({ behavior: "smooth" });
  };
  const objectives = [
    {
      icon: <FaLightbulb />,
      title: "Curiosité",
      description: "Encourager l'exploration et l'envie d'apprendre.",
    },
    {
      icon: <FaBookOpen />,
      title: "Connaissance",
      description: "Offrir un enseignement de qualité et adapté.",
    },
    {
      icon: <FaHandsHelping />,
      title: "Respect",
      description: "Favoriser un environnement bienveillant et inclusif.",
    },
    {
      icon: <FaUsers />,
      title: "Coopération",
      description: "Apprendre ensemble et développer l'esprit d'équipe.",
    },
  ];
  const cards = [
    {
      icon: <FaBookOpen className="text-3xl text-olive" />,
      title: "Excellence académique",
      desc: "Enseignement conforme à l’Éducation nationale, avec l’arabe en LV1 et l’anglais en LV2.",
    },
    {
      icon: <FaMosque className="text-3xl text-olive" />,
      title: "Valeurs et spiritualité",
      desc: "Éducation inspirée de la culture musulmane, favorisant paix et vivre-ensemble.",
    },
    {
      icon: <FaGlobe className="text-3xl text-olive" />,
      title: "Engagement citoyen",
      desc: "Sensibilisation à l’écocitoyenneté et à la solidarité pour former des citoyens responsables.",
    },
  ];
  return (
    <>
      <main className="notre-histoire w-full">
        <section
          className="histoire-home-page relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-no-repeat "
          style={{
            backgroundImage: "url('/assets/images/background3.webp')",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <motion.div
            className="relative flex flex-col items-center gap-6 text-white text-center px-6"
            initial={{ opacity: 0, y: 50 }} // Définir l'initialisation de l'élément
            whileInView={{ opacity: 1, y: 0 }} // L'élément devient complètement visible et revient à sa position d'origine
            transition={{ duration: 1 }} // Durée de l'animation
            viewport={{ once: true }} // L'animation ne se déclenche qu'une seule fois lorsque l'élément est visible
          >
            <h1 className="text-4xl sm:text-5xl">
              Histoire et Objectifs
            </h1>
            <p className="text-xl italic !text-center">
              Plongez dans notre histoire et découvrez les valeurs qui guident
              notre école.
            </p>
            {/* Bouton pour faire défiler vers la section suivante */}
            <button
              onClick={scrollToNextSection}
              className="mt-6 px-6 py-3 bg-vertLogo text-white text-lg rounded-full hover:bg-green-600 transition-all"
            >
              Voir la suite
            </button>
          </motion.div>
        </section>

        <section
          id="historique"
          className="historique flex flex-col items-center justify-center py-10 px-6"
        >
          <div className="max-w-3xl text-center">
            <h2 className="text-3xl lg:text-4xl mb-4">
              Création de l'école l'Olivier
            </h2>
            <span className="text-gray-600">
              Depuis sa création, notre école transmet un enseignement de
              qualité <br/> fondé sur des valeurs solides.
            </span>
          </div>
          <div className="timeline-histoire-container max-w-5xl mt-20 flex flex-col gap-8">
            <CardHistory />
          </div>
        </section>
        {/* Cartes projet educative */}
        <section className="py-12 px-6 bg-gray-50">
          <div className="max-w-4xl mx-auto text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-3">
              Un projet éducatif unique
            </h2>
            <span className="text-gray-600">
              L’école L’Olivier allie excellence académique, transmission des
              valeurs et ouverture sur le monde. Elle propose un enseignement
              conforme à l’Éducation nationale tout en intégrant une dimension
              spirituelle et citoyenne adaptée.
            </span>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-4xl mx-auto">
            {cards.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center bg-white shadow-md p-6 rounded-xl"
              >
                {card.icon}
                <h3 className="text-lg font-semibold mt-3 mb-2">{card.title}</h3>
                <span className="text-gray-600 text-sm">{card.desc}</span>
              </div>
            ))}
          </div>
        </section>
        <motion.section
          className="nos-objectifs py-10 bg-[#E9EBC4]"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: false }}
        >
          <div className="flex flex-col items-center justify-center py-10">
            <h2 className="text-center text-3xl lg:text-4xl font-bold mb-10 text-[#848660]">
              Nos Objectifs
            </h2>
            <div className="relative flex flex-wrap justify-center gap-10 w-3/4">
              {objectives.map((obj, index) => (
                <motion.div
                  key={index}
                  className="relative bg-white shadow-lg p-6 rounded-2xl w-60 text-center flex flex-col items-center border-4 border-[#BABC79]"
                  initial={{ opacity: 0, y: 20 }} // Initial position (invisible and slightly below)
                  whileInView={{ opacity: 1, y: 0 }} // Becomes visible and moves to original position
                  transition={{ duration: 0.6, delay: index * 0.2 }} // Stagger the animations
                >
                  <div className="text-[#8F9324] text-4xl">{obj.icon}</div>
                  <h3 className="font-bold text-lg mt-3 mb-2 text-[#848660]">
                    {obj.title}
                  </h3>
                  <span className="text-[#848660]">{obj.description}</span>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.section>
      </main>
    </>
  );
};
