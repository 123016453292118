import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { motion } from "framer-motion";

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

export const ProjetPedagogique = () => {
  const location = useLocation();

  useEffect(() => {
    document.title =
      "Projet Pédagogique - École Primaire Privée Musulman L'Olivier Marseille";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Découvrez le projet pédagogique de l'école primaire L'Olivier, qui place l'éducation au cœur du développement des enfants."
      );
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content =
        "Découvrez le projet pédagogique de l'école primaire L'Olivier, qui place l'éducation au cœur du développement des enfants.";
      document.head.appendChild(metaTag);
    }
  }, [location]);

  return (
    <section className="w-full max-w-5xl mx-auto py-16 px-6 bg-white">
      {/* Header */}
      <motion.div
        className="text-center mb-12"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h2 className="text-4xl mb-5 font-bold text-[#8F9324]">
          Projet Pédagogique
        </h2>
        <span className="text-lg text-gray-600 mt-2">
          Découvrez nos méthodes d'enseignement et la pédagogie qui guide nos
          élèves, du Maternelle au Primaire. <br />
          <br />
        </span>
        <span className="text-gray-600 mt-6 text-lg">
          À l'École Les Oliviers, nous croyons en une pédagogie innovante, où
          chaque enfant est au cœur de son apprentissage. Notre objectif est de
          créer un environnement bienveillant et stimulant, afin que chaque
          élève puisse s'épanouir pleinement et développer ses compétences à son
          propre rythme.
        </span>
      </motion.div>

      {/* Méthodes d'enseignement */}
      <motion.div
        className="grid md:grid-cols-2 gap-8 mb-16"
        initial="hidden"
        animate="visible"
        variants={{ visible: { transition: { staggerChildren: 0.2 } } }}
      >
        {[
          {
            title: "Approche personnalisée",
            desc: "Un suivi individualisé permettant à chaque élève de progresser à son rythme et de développer son plein potentiel.",
          },
          {
            title: "Pédagogie active",
            desc: "Des méthodes d’enseignement interactives favorisant l’autonomie, la réflexion et l’engagement des élèves.",
          },
          {
            title: "Apprentissage collaboratif",
            desc: "Un environnement où le travail en groupe et l’entraide sont encouragés pour renforcer les compétences sociales et cognitives.",
          },
          {
            title: "Apprentissage islamique",
            desc: "Un enseignement religieux structuré, distinct des matières académiques, pour transmettre des valeurs éthiques et spirituelles.",
          },
        ].map((method, index) => (
          <motion.div
            key={index}
            className="p-6 border border-gray-300 rounded-lg shadow-md bg-white"
            variants={fadeIn}
          >
            <h3 className="text-xl font-semibold text-[#8F9324]">
              {method.title}
            </h3>
            <span className="text-gray-600 mt-2 text-sm">{method.desc}</span>
          </motion.div>
        ))}
      </motion.div>

      {/* Taux de Réussite */}
      {/* <motion.div
        className="mb-16"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h3 className="text-2xl font-semibold text-[#8F9324] text-center mb-6">
          Nos Taux de Réussite
        </h3>
        <div className="grid md:grid-cols-2 gap-8">
          {[
            { classe: "Maternelle", taux: "95%" },
            { classe: "Primaire", taux: "98%" },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="p-6 border border-gray-300 rounded-lg shadow-md bg-white"
              variants={fadeIn}
            >
              <h4 className="text-xl font-semibold text-[#8F9324]">
                {item.classe}
              </h4>
              <p className="text-gray-600 mt-2 text-sm">
                Taux de réussite : {item.taux}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div> */}

      {/* Valeurs pédagogiques */}
      <motion.div
        className="mb-16"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h3 className="text-2xl font-semibold text-[#8F9324] text-center mb-6">
          Nos Valeurs Fondamentales
        </h3>
        <div className="grid md:grid-cols-2 gap-8">
          {[
            {
              title: "Respect",
              description:
                "Un cadre bienveillant où chacun apprend à valoriser autrui.",
            },
            {
              title: "Engagement",
              description:
                "Encourager l’implication et la persévérance dans chaque apprentissage.",
            },
            {
              title: "Solidarité",
              description:
                "Développer l’entraide et le sens du collectif au quotidien.",
            },
            {
              title: "Excellence",
              description:
                "Viser le dépassement de soi pour atteindre son plein potentiel.",
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="p-6 border border-gray-300 rounded-lg shadow-md bg-white"
              variants={fadeIn}
            >
              <h4 className="text-xl font-semibold text-[#8F9324]">
                {item.title}
              </h4>
              <span className="text-gray-600 mt-2 text-sm">
                {item.description}
              </span>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Approfondir la pédagogie */}
      <motion.div
        className="mb-16"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h3 className="text-2xl font-semibold text-[#8F9324] text-center mb-6">
          Une pédagogie tournée vers l'avenir
        </h3>
        <p className="text-gray-600 mt-4 text-lg text-center">
          Notre pédagogie repose sur des valeurs essentielles : respect,
          engagement, solidarité. Chaque étape de l'apprentissage est pensée
          pour répondre aux besoins spécifiques de chaque élève. Du Maternelle
          au Primaire, nous mettons en place des méthodes actives et
          participatives qui stimulent la curiosité et l'autonomie des enfants.
        </p>
      </motion.div>

      {/* Bouton CTA */}
      <motion.div
        className="text-center"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.a
          href="/assets/pdf/projet-educatif-pedagogique.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="px-6 py-3 bg-[#8F9324] text-white rounded-lg shadow-lg hover:bg-gray-800 transition cursor-pointer"          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          En savoir plus
        </motion.a>
      </motion.div>
    </section>
  );
};
