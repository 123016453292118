import "./index.css";
import { React, useEffect } from "react";
import { Home } from "./home/Home";
import { InfoEcole } from "./infosEcole/InfoEcole";
import { Propos } from "./aPropos/Propos";
import { CagnottePopup } from "../composants/popup/CagnottePopup";

export const Index = () => {
  useEffect(() => {
    document.title = "École Privée Musulmane à Marseille - L'Olivier";

    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Découvrez l'école privée musulmane L'Olivier à Marseille. Éducation de qualité, apprentissage du Coran, de l'arabe et des sciences musulmanes avec le programme français."
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Découvrez l'école privée musulmane L'Olivier à Marseille. Éducation de qualité, apprentissage du Coran, de l'arabe et des sciences musulmanes avec le programme français.";
      document.head.appendChild(meta);
    }

    const metaKeywords = document.querySelector("meta[name='keywords']");
    if (metaKeywords) {
      metaKeywords.setAttribute(
        "content",
        "école musulmane, ecole l'olivier marseille, ecole musulman marseille, ecole primaire marseille musulamne, école privée Marseille, éducation islamique, apprentissage Coran"
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "keywords";
      meta.content =
        "école musulmane, école privée Marseille, éducation islamique, apprentissage Coran, école arabe Marseille";
      document.head.appendChild(meta);
    }
  }, []);

  return (
    <>
      <CagnottePopup/>
      <section
        className="home-page bg-overlay "
        style={{ minHeight: "calc(100vh - 90px)" }}
      >
        <div className="img"></div>
        <Home />
        <div className="clip-transition "></div>
      </section>

      <section className="infos-page min-h-dvh bg-[#f5f5dc]">
        <InfoEcole />
      </section>

      <section
        className="propos-page min-h-dvh bg-white"
        style={{ minHeight: "calc(100dvh - 90px)" }}
      >
        <Propos />
      </section>
    </>
  );
};
