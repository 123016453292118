import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Timeline } from "flowbite-react";
import { IoCalendarNumber } from "react-icons/io5";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export function TimelineComponent() {
  const customTheme = {
    root: {
      direction: {
        horizontal: "xl:flex",
      },
    },
  };
  return (
    <div className="w-3/4 mt-5">
      <Timeline horizontal theme={customTheme} className="gap-8">
        {/* Timeline Item 1 */}
        <motion.div
          initial={{ opacity: 0, y: 20 }} // Départ avec opacité 0 et décalage vertical
          whileInView={{ opacity: 1, y: 0 }} // Lorsqu'il entre dans la vue, devient visible
          transition={{ duration: 0.5, delay: 0.1 }} // Animation de 0.5s avec un délai de 0.1s
          viewport={{ once: true }} // L'animation se déclenche uniquement une fois
        >
          <Timeline.Item>
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-vertLogo text-white">
              <IoCalendarNumber className="w-6 h-6" />
            </div>
            <Timeline.Content>
              <Timeline.Time>Fondée en 2002 </Timeline.Time>
              <Timeline.Title>L’école L’Olivier</Timeline.Title>
              <Timeline.Body>
                Fondée en 2002 à Marseille par Mr. Salim Fathi, est la première
                primaire musulmane de France, créée pour répondre à une demande
                d’éducation conforme aux valeurs islamiques.
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </motion.div>

        {/* Timeline Item 2 */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
        >
          <Timeline.Item>
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-vertLogo text-white">
              <IoCalendarNumber className="w-6 h-6" />
            </div>
            <Timeline.Content>
              <Timeline.Time>2015</Timeline.Time>
              <Timeline.Title>Mr. Hassanein Mohamed prend la direction et agrandit l’école.</Timeline.Title>
              <Timeline.Body>
                L’association fut reprise par M. Hassanein Mohamed suite au décès de M. Fathi. Il initia un projet d’agrandissement pour
                doubler le nombre de classes, permettant à l’école d’accueillir
                le double d’élèves, de la maternelle au CM2.
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </motion.div>

        {/* Timeline Item 3 */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <Timeline.Item>
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-vertLogo text-white">
              <IoCalendarNumber className="w-6 h-6" />
            </div>
            <Timeline.Content>
              <Timeline.Time>2022</Timeline.Time>
              <Timeline.Title>
              20 ans d'engagement et de réussite
              </Timeline.Title>
              <Timeline.Body>
                L’Olivier forme des élèves depuis 20 ans, alliant rigueur
                académique, valeurs islamiques et principes républicains pour
                préparer les jeunes à leur avenir.
              </Timeline.Body>
            </Timeline.Content>
          </Timeline.Item>
        </motion.div>
      </Timeline>

      <Link to={"/notre-histoire"}>
        <Button className="bg-vertLogo">Lire plus</Button>
      </Link>
    </div>
  );
}
