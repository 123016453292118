import { Typography, Avatar, Rating } from "@material-tailwind/react";

export function Reviews() {
  const srcImg = "/assets/images/";
  const data = [
    {
      img: `${srcImg}reviews1.webp`,
      name: "Math Saf",
      message:
        "Ma fille est dans cette école depuis 3 année déjà et j'en suis vraiment satisfaite. Al hamdouliLlah chaqune des maîtresse qu'elle a eu ont été douce patiente gentille et surtout de vraie résultat dans le travail. Qu Allah preserve notre école 🥰",
      rating: 5,
    },
    {
      img: `${srcImg}reviews1.webp`,
      name: "Oum Ibnati",
      message:
        "superbe école !!! très satisfaite de l enseignement. C'est une vraie ni3maa, dommage qu'il n'y ait pas davantage d écoles privées musulmanes à Marseille , afin que d autres enfants bénéficient d un enseignement de qualité comme celui de l école l olivier, car les places sont limitées.",
      rating: 5,
    },
    {
      img: `${srcImg}reviews2.webp`,
      name: "Sonia Bichiou",
      message:
        "Ma fille est en petite section. Elle est heureuse et va à l'école en courant. La maîtresse et la tata sont vraiment efficaces. Merci à elles. Je ne regrette pas mon choix. Bien au contraire.",
      rating: 5,
    },
  ];

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 py-8 px-6">
      {data.map((review, index) => (
        <div
          key={index}
          className="flex flex-col items-center text-center justify-center p-6 w-full border-2 border-[#848660] rounded-2xl shadow-lg"
        >
          <Avatar src={review.img} alt="avis d'utilisateur" size="lg" />
          <Typography variant="p@" className="mt-4 text-[#8F9324] font-bold">
            {review.name}
          </Typography>
          <Typography
            variant="body2"
            color="blue-gray"
            className="mt-2 text-gray-700"
          >
            {review.message}
          </Typography>
          <div className="mt-4">
            <Rating value={Math.round(review.rating)} readonly />
          </div>
        </div>
      ))}
    </div>
  );
}
