import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom"; // Si tu utilises React Router

export const CagnottePopup = () => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Empêche le scroll
    } else {
      document.body.style.overflow = "auto"; // Réactive le scroll
    }

    return () => {
      document.body.style.overflow = "auto"; // Nettoyage quand le composant est démonté
    };
  }, [isOpen]);

  if (!isOpen) return null;


  return (
    isOpen && (
      <motion.div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className="bg-white p-6 rounded-xl shadow-lg w-11/12 max-w-md relative text-center"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <button
            className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
            onClick={() => setIsOpen(false)}
          >
            <MdClose size={24} />
          </button>

          <h2 className="text-2xl font-semibold text-[#8F9324] mb-4">
            Participez à notre cagnotte !
          </h2>
          <p className="text-gray-700">
            Aidez-nous à offrir un cadre éducatif de qualité aux enfants. Chaque contribution compte !
          </p>

          <Link
            to="/nous-soutenir"
            className="mt-4 inline-block px-6 py-3 text-lg font-medium rounded-lg shadow-md transition-all"
            style={{
              backgroundColor: "#8F9324",
              color: "#e9ebc4",
            }}
          >
            Découvrir la cagnotte
          </Link>
        </motion.div>
      </motion.div>
    )
  );
};
