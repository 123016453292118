import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./pages/contact/Contact.jsx";
import { NavBar } from "./composants/navigation/NavBar";
import { Footer } from "./composants/footer/Footer";
import { NotFound } from "./pages/404/NotFound.jsx";
import { Index } from "./pages/Index.jsx";
import { Presentation } from "./pages/quiSommeNous/histoire/Presentation.jsx";
import ScrollToTop from "./composants/hooks/ScrollToTop.jsx";
import { ProjetPedagogique } from "./pages/quiSommeNous/projet-pedagogique/ProjetPedagogique.jsx";
import ModalitesInscription from "./pages/modalites-inscription/ModalitesInscription.jsx";
import { Evenement } from "./pages/evenement/Evenement.jsx";
import { ButtonTop } from "./composants/buttonTop/ButtonTop.jsx";
import { ActivitesPedagogique } from "./pages/activite-pedagogique/ActivitesPedagogique.jsx";
import { NousSoutenir } from "./pages/nous-soutenir/NousSoutenir.jsx";


function App() {
  return (
    <Router>
      <ButtonTop/>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/notre-histoire" element={<Presentation />} />
        <Route path="/projet-pedagogique" element={<ProjetPedagogique />} />
        {/* <Route path="/activites-pedagogique" element={<ActivitesPedagogique />} /> */}
        <Route
          path="/modalites-inscription"
          element={<ModalitesInscription />}
        />
        <Route path="/evenements" element={<Evenement />} />
        <Route path="/nous-soutenir" element={<NousSoutenir />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
