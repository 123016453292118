import { forwardRef, React, useState, useEffect } from "react";
import { Card, CardBody } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const CardInfo = ({ nbr, mess, color }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: true }); // Animation une seule fois

  useEffect(() => {
    if (inView) {
      let start = 0;
      const duration = 2000; // Durée totale en ms
      const interval = 30; // Intervalle entre chaque mise à jour
      const step = nbr / (duration / interval); // Pas d'incrémentation

      const counter = setInterval(() => {
        start += step;
        if (start >= nbr) {
          start = nbr;
          clearInterval(counter);
        }
        setCount(Math.floor(start));
      }, interval);
    }
  }, [inView, nbr]);

  return (
    <>
      <Card
        className={`w-80 h-36 flex items-center`}
        style={{ background: color, boxShadow: `0px 4px 29px -4px ${color}` }}
      >
        <CardBody>
          <p className="flex flex-col items-center text-center text-white font-semibold">
            {/* <span className="text-2xl">{nbr}</span> */}
            <motion.span
              ref={ref}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
              className="text-3xl font-bold mb-3"
              aria-live="polite" // Cela permettra aux lecteurs d'écran de réagir à l'animation
            >
            {count}
            </motion.span>
            {mess}
          </p>
        </CardBody>
      </Card>
    </>
  );
};
