import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-tailwind/react";

export function NotFound() {
  return (
    <div className="h-screen mx-auto mt-10 text-center px-8">
      <div>
        <div className="img-404 mx-auto w-72">
          <img src="/assets/images/404-img.webp" alt="404 images" />
        </div>
        <Typography
          variant="h1"
          color="blue-gray"
          className="mt-10 !text-3xl !leading-snug md:!text-4xl"
        >
          Erreur 404
        </Typography>
        <Typography className="mt-8 mb-14 text-[18px] font-normal text-gray-500 mx-auto md:max-w-sm">
          On dirait que quelque chose s'est mal passé.
        </Typography>
        <Link to='/'>
        <Button
          color="brown"
          variant="gradient"
          className="w-full px-4 md:w-[10rem]"
          >
          Retour a l'école
        </Button>
            </Link>
      </div>
    </div>
  );
}

export default NotFound;
