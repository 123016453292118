import React from "react";
import { motion } from "framer-motion";

export const CardHistory = () => {
  const data = [
    {
      title: "2002: Création de l'École l'Olivier",
      desc: `
       L’école primaire privée L’Olivier, première école musulmane de France, située à Marseille, a vu le jour en septembre 2002, sous l’initiative de son fondateur Mr. Salim Fathi et de son épouse Mme Salim Josseline, qui en a assuré la direction jusqu’en 2022.
        Docteur en biologie, imam et libraire, il était naturellement sollicité par la communauté qui voyait en lui une référence fiable et bienveillante.
        Face à une demande grandissante d’éducation conforme aux valeurs de l’islam, il a répondu à cet appel.
        Il fonda l’association L’Olivier qui gère jusqu’à ce jour l’école L’Olivier et fut rejoint par des bénévoles engagés qui se sont fédérés autour d’un projet ambitieux, animés par la volonté de transmettre le savoir et de servir les besoins éducatifs de la communauté : 
        ouvrir la première école primaire musulmane de France.
      `,
      img:"/assets/images/histoire1.webp",
      reverse: false,
    },
    {
      title: "2015: La succession du Président de l'école ",
      desc: "En 2015, à la mort du regretté “cheikh Fathi” que Dieu fasse miséricorde à son âme, l’association a été reprise par l’un de ses bénévoles, Mr. Hassanein Mohamed, médecin cancérologue, imam et ami de Mr. Salim Fathi. Face à une demande d’inscriptions croissante, le successeur du fondateur a fait preuve d’une ambition et d’une persévérance remarquables. Déterminé à répondre aux besoins éducatifs grandissants, il a initié un vaste projet d’agrandissement visant à doubler le nombre de classes. Grâce à ces efforts, l’établissement a pu élargir son offre et accueille aujourd’hui un nombre d’élèves deux fois supérieur, de la petite section de maternelle au CM2, témoignant ainsi d’un engagement fort envers l’éducation et la communauté.",
      img:"/assets/images/histoire2.webp",
      reverse: true,
    },
    {
      title: "20 ans d’engagement et de réussite.",
      desc: "Depuis plus de 20 ans, L’Olivier a formé de nombreux élèves devenus étudiants et professionnels, en conjuguant rigueur académique, éducation spirituelle et engagement citoyen. Son approche moderne et inclusive allie valeurs de l’islam et principes républicains, ouvrant les élèves sur le monde et leur avenir.",
      img:"/assets/images/histoire3.webp",
      reverse: false,
    },
  ];

  return (
    <>
      {data.map((item, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, x: item.reverse ? 50 : -50 }} // Animation initiale (déplacement vers la droite ou gauche)
          whileInView={{ opacity: 1, x: 0 }} // Animation quand l'élément entre dans la vue
          transition={{ duration: 0.8 }} // Durée de l'animation
          viewport={{ once: false }} // Déclenche l'animation uniquement une fois quand il est visible
          className={`flex flex-col-reverse sm:flex-row ${
            item.reverse ? "sm:flex-row-reverse" : ""
          } gap-6 sm:gap-10 items-center`}
        >
          <div className="w-full sm:w-1/2">
            <img
              src={item.img}
              alt="Image de l'évolution de l'École L'Olivier de Marseille"
              className="w-full h-60 sm:h-96 object-cover rounded-md"
            />
          </div>
          <div className="text-histoire flex flex-col gap-6 sm:gap-10 w-full sm:w-1/2 px-4 sm:px-0 text-center sm:text-left">
            <p className="title-history text-lg font-bold">{item.title}</p>
            <p className="text-gray-700 leading-relaxed">{item.desc}</p>
          </div>
        </motion.div>
      ))}
    </>
  );
};
