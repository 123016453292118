import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Spinner } from "@material-tailwind/react";
import { FormContact } from "../../composants/formulaire/FormContact";

export function Contact() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Contact - École L'Olivier";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Contactez l'école primaire L'Olivier pour toute question concernant nos programmes, inscriptions ou autres informations."
      );
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content =
        "Contactez l'école primaire L'Olivier pour toute question concernant nos programmes, inscriptions ou autres informations.";
      document.head.appendChild(metaTag);
    }
  }, [location]);

  return (
    <section className="px-8 py-8 lg:py-16">
      <div className="container mx-auto text-center">
        <h1 className="text-4xl sm:text-5xl mb-4">
        Contactez-nous
        </h1>

        <Typography className="mb-10 font-normal !text-center !text-lg lg:mb-20 mx-auto max-w-3xl !text-gray-500">
          Pour toute demande d'informations concernant l'École l'Olivier ou
          l'inscription
        </Typography>
        <div className="grid grid-cols-1 gap-x-12 gap-y-6 lg:grid-cols-2 items-start lg:place-items-center lg:w-3/4 lg:mx-auto">
          <div className="h-full w-full flex justify-center items-center border-vertLogo border-4">
            {loading && (
              <Spinner className="h-16 w-16 text-gray-900/50 absolute " />
            )}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2535.2199047809922!2d5.372398674214688!3d43.32535733203997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9c06717b206e3%3A0x52abdbd32a43e320!2s%C3%89cole%20primaire%20priv%C3%A9e%20l&#39;Olivier!5e0!3m2!1sfr!2sfr!4v1734200812049!5m2!1sfr!2sfr"
              style={{ border: 0 }}
              className="w-full h-full"
              allowFullScreen
              onLoad={() => setLoading(false)}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="l'École Privée l'Olivier"
            ></iframe>
          </div>
          <FormContact />
        </div>
      </div>
    </section>
  );
}

export default Contact;
