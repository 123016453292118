import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { PDFDocument } from "pdf-lib"; // Importation de pdf-lib
import {
  Button,
  Input,
  Textarea,
  Typography,
  Alert,
  Select,
  Option,
} from "@material-tailwind/react";

export const FormContact = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState("");
  const [selectedClass, setSelectedClass] = useState(""); 
  const [message, setMessage] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfPreview, setPdfPreview] = useState("");

  

  const handleCheckboxChange = (value) => {
    setSelectedRequest(selectedRequest === value ? "" : value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size > 5 * 1024 * 1024) {
          // 5MB max
          setErrorMessage("Le fichier PDF ne doit pas dépasser 5MB");
          setColor("red");
          return;
        }

        setPdfFile(file);

        // Créer un preview du nom de fichier
        setPdfPreview(file.name);

        // Si vous voulez aussi afficher une preview (optionnel)
        // const base64 = await convertToBase64(file);
        // setPdfPreview(base64);
      } else {
        setErrorMessage("Veuillez uploader un fichier PDF valide.");
        setColor("red");
      }
    }
  };

  // Fonction de compression PDF
  const compressPdf = async (file) => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = async () => {
        const pdfBytes = new Uint8Array(fileReader.result);
        const pdfDoc = await PDFDocument.load(pdfBytes);
        
        // Effectuer la compression : extraction et création d'un PDF simple
        const compressedPdfBytes = await pdfDoc.save();
        const compressedPdfBlob = new Blob([compressedPdfBytes], { type: "application/pdf" });
        const compressedFile = new File([compressedPdfBlob], file.name, { type: "application/pdf" });
        resolve(compressedFile);
      };
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });
  };

  const validateMessage = (text) => {
    return text.replace(/\s+/g, " ").trim().length >= 200;
  };

  const handleMessageChange = (e) => {
    const text = e.target.value;
    setMessage(text);
    if (!validateMessage(text)) {
      setErrorMessage("Votre message doit contenir au moins 200 caractères.");
      setColor("red");
    } else {
      setErrorMessage("");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    // Validation
    if (!selectedRequest) {
      setErrorMessage("Veuillez sélectionner une demande.");
      setColor("red");
      return;
    }

    if (!validateMessage(message)) {
      setErrorMessage("Votre message doit contenir au moins 200 caractères.");
      setColor("red");
      return;
    }

    setLoading(true);

    try {
      // Convertir le PDF en base64 si présent
      let pdfBase64 = "";
      let compressedPdfFile = pdfFile; // Par défaut, le fichier PDF est celui téléchargé

       // Si un fichier PDF est téléchargé, on le compresse avant de l'envoyer
    if (pdfFile) {
      // Compression du fichier PDF
      compressedPdfFile = await compressPdf(pdfFile);
      pdfBase64 = await convertToBase64(compressedPdfFile); // Convertir en base64
    }
      // Préparer les paramètres pour EmailJS
      const templateParams = {
        request_type: selectedRequest,
        last_name: e.target["last-name"].value,
        first_name: e.target["first-name"].value,
        last_name_2: e.target["last-name-2"].value || "",
        first_name_2: e.target["first-name-2"].value || "",
        last_name_children: e.target["last-name-children"].value,
        first_name_children: e.target["first-name-children"].value,
        naissance: e.target.naissance.value,
        classe: selectedClass,
        email: e.target.email.value,
        phone: e.target.phone.value,
        message: message,
        has_pdf: !!pdfFile,
        pdf_name: pdfFile ? pdfFile.name : "",
        pdf_content: pdfBase64, // Envoie le contenu en base64
        pdf_size: pdfFile ? (pdfFile.size / 1024).toFixed(2) + "KB" : "",
      };

      // Envoi via EmailJS
      await emailjs.send(
        "service_kii1kqo",
        "template_79rta5a",
        templateParams,
        "3wrWyCDmSy0nsxKTA"
      );

      // Succès
      setSuccessMessage("Votre message a été envoyé avec succès !");
      setColor("success");
      form.current.reset();
      setMessage("");
      setPdfFile(null);
      setPdfPreview("");
    } catch (error) {
      console.error("Erreur lors de l'envoi:", error);
      setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
      setColor("error");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
    }
  };

  return (
    <>
      {loading && (
        <div className="bg-black opacity-50 w-screen h-screen z-[99] fixed top-0 left-0 flex items-center justify-center">
          <span className="loading loading-dots loading-lg text-white"></span>
        </div>
      )}

      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col gap-4 lg:max-w-sm"
      >
        <Typography
          variant="small"
          className="text-left !font-semibold !text-gray-600"
        >
          Sélectionnez votre demande
        </Typography>
        <div className="flex gap-4">
          <label className="flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="request_type"
              value="Inscription"
              checked={selectedRequest === "Inscription"}
              onChange={() => handleCheckboxChange("Inscription")}
              className="accent-brown-500"
            />
            Inscription
          </label>
          <label className="flex items-center gap-2 cursor-pointer">
            <input
              type="checkbox"
              name="request_type"
              value="Information"
              checked={selectedRequest === "Information"}
              onChange={() => handleCheckboxChange("Information")}
              className="accent-brown-500"
            />
            Information
          </label>
        </div>
        {errorMessage && errorMessage.includes("demande") && (
          <p className="text-red-600 text-sm mt-1">{errorMessage}</p>
        )}

        <div className="grid grid-cols-2 gap-4 mt-2">
          <div>
            <Input
              color="gray"
              size="lg"
              placeholder="Nom du parent"
              label="Nom du parent"
              name="last-name"
              required
              containerProps={{ className: "!min-w-full" }}
            />
          </div>
          <div>
            <Input
              color="gray"
              size="lg"
              placeholder="Prénom du parent"
              name="first-name"
              label="Prénom du parent"
              required
              containerProps={{ className: "!min-w-full" }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div>
            <Input
              color="gray"
              size="lg"
              placeholder="Nom du parent 2"
              label="Nom du parent 2"
              name="last-name-2"
              containerProps={{ className: "!min-w-full" }}
            />
          </div>
          <div>
            <Input
              color="gray"
              size="lg"
              placeholder="Prénom du parent 2"
              name="first-name-2"
              label="Prénom du parent 2"
              containerProps={{ className: "!min-w-full" }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div>
            <Input
              color="gray"
              size="lg"
              placeholder="Nom de l'enfant"
              label="Nom de l'enfant"
              name="last-name-children"
              required
              containerProps={{ className: "!min-w-full" }}
            />
          </div>
          <div>
            <Input
              color="gray"
              size="lg"
              placeholder="Prénom de l'enfant"
              name="first-name-children"
              label="Prénom de l'enfant"
              required
              containerProps={{ className: "!min-w-full" }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div>
            <Input
              type="date"
              color="gray"
              size="lg"
              placeholder="Naissance de l'enfant"
              label="Naissance de l'enfant"
              name="naissance"
              required
              containerProps={{ className: "!min-w-full mt-2" }}
              aria-label="Date de naissance de l'enfant"
            />
          </div>
          <div>
            <Select
              value={selectedClass}
              label="Classe souhaitée"
              name="classe"
              onChange={(value) => setSelectedClass(value)}
              required
              containerProps={{ className: "min-w-full mt-2" }}
              aria-label="Classe de l'enfant souhaitée"
            >
              {/* Maternelle */}
              <Option disabled className="text-olive font-extrabold">
                Maternelle
              </Option>
              <Option value="petite-section">Petite Section</Option>
              <Option value="moyenne-section">Moyenne Section</Option>
              <Option value="grande-section">Grande Section</Option>

              {/* Primaire */}
              <Option disabled className="text-olive font-extrabold">
                Primaire
              </Option>
              <Option value="cp">CP</Option>
              <Option value="ce1">CE1</Option>
              <Option value="ce2">CE2</Option>
              <Option value="cm1">CM1</Option>
              <Option value="cm2">CM2</Option>
            </Select>
          </div>
        </div>
        <div>
          <Input
            color="gray"
            size="lg"
            placeholder="email@email.com"
            label="Email"
            name="email"
            required
            type="email"
          />
        </div>
        <div>
          <Input
            color="gray"
            size="lg"
            placeholder="Numéro de téléphone"
            label="Numéro de téléphone"
            name="phone"
            required
            type="tel"
          />
        </div>

        <div>
          <Textarea
            color="gray"
            size="lg"
            label="Message"
            name="message"
            value={message}
            onChange={handleMessageChange}
            required
          />
          {errorMessage && errorMessage.includes("message") && (
            <p className="text-red-600 text-sm mt-1">{errorMessage}</p>
          )}
        </div>

        {/* <div>
          <label className="block mt-2 font-bold">
            Joindre une lettre de motivation
          </label>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            className="mt-2"
          />
          {pdfPreview && <p className="mt-1 text-sm">{pdfPreview}</p>}
        </div> */}

        <Button
          type="submit"
          className="w-full bg-vertLogo"
          disabled={loading}
        >
          {loading ? "Envoi..." : "Envoyer"}
        </Button>
      </form>

        {(successMessage || errorMessage) && (
        <div>
          <Alert
            className={`alert ${
              color === "success" ? "bg-olive" : "bg-red-600"
            } z-50 max-w-80 fixed top-20 right-2`}
          >
            {successMessage || errorMessage}
          </Alert>
        </div>
      )}
    </>
  );
};
