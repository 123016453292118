import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="flex w-full flex-row flex-wrap items-center gap-y-6 gap-x-12 b border-blue-gray-50 p-6 text-center md:justify-between">
      <div className="flex gap-3 items-center">
        <span className="font-medium">&copy; 2024 École l'Olivier - Tous droits réservés</span>
        <a
          href="https://www.facebook.com/people/%C3%89cole-Lolivier/pfbid0FmwwDBkT3GmmGJwSG675SVWn6uajqF4FeHPDEpVry1ZEmcjmy9wPPGFCom4ymhMrl/?ref=ig_profile_ac"
          target="_blank"
        >
          <FaFacebook className="w-5 h-5" />
          <span className=" sr-only">Lien vers le compte Facebook</span>
        </a>
        <a
          href="https://www.instagram.com/ecolepriveelolivier/?igsh=OXF6bmozam9vaHBz"
          target="_blank"
        >
          <FaInstagram className="w-5 h-5" />
          <span className=" sr-only">Lien vers le compte Instagram</span>

        </a>
        <a href="https://www.youtube.com/@ecolepriveelolivier" target="_blank">
        <FaYoutube className="w-5 h-5" />
        <span className=" sr-only">Lien vers le compte Youtube</span>
        </a>
      </div>
      <ul className="flex flex-wrap items-center gap-y-5 gap-x-8 font-medium cursor-pointer">
        <Link to={'/notre-histoire'}>
          <li href="#" className="transition-colors focus:text-blue-500">
            Histoire
          </li>
        </Link>
        <Link to={"/projet-pedagogique"}>
          <li
            href="#"
            className="font-medium transition-colors focus:text-blue-500"
          >
            Projet Pédagogique
          </li>
        </Link>
        {/* <Link to={"/activites-pedagogique"}>
          <li
            href="#"
            className="font-medium transition-colors focus:text-blue-500"
          >
            Activités pédagogique
          </li>
        </Link> */}
        <Link to={'/evenements'}>
          <li
            href="#"
            className="font-medium transition-colors focus:text-blue-500"
          >
            Évènements
          </li>
        </Link>
        <Link to={'/modalites-inscription'}>
          <li
            href="#"
            className="font-medium transition-colors focus:text-blue-500"
          >
            Inscription
          </li>
        </Link>
        <Link to={'/nous-soutenir'}>
          <li
            href="#"
            className="font-medium transition-colors focus:text-blue-500"
          >
            Nous-soutenir
          </li>
        </Link>
        <Link to={'/contact'}>
          <li
            href="#"
            className="font-medium transition-colors focus:text-blue-500"
          >
            Contactez-nous
          </li>
        </Link>
      </ul>
    </footer>
  );
}
