import { React, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import logo from "/assets/images/logo-lolivier_nav.webp";
import "./nav.css";

export const NavBar = () => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  // Ferme le menu si on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setActive(false);
  }, [location]);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Nettoyer le style lors du démontage
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [active]);

  return (
    <>
      <header className="bg-white">
        <nav className="w-full flex items-center justify-between py-5 px-10 border-b-4 border-brown-00 border-vertLogo">
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="" width={50} height={50} />
              <span className=" sr-only">lien vers l'accueil</span>
            </div>
          </Link>
          <button
            className="menu-burger xl:hidden z-50 w-7 h-7"
            onClick={() => {
              setActive(!active);
            }}
          >
            {active ? (
              <FontAwesomeIcon icon={faXmark} className="w-full h-full" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="w-full h-full" />
            )}
          </button>

          <ul
            className={`group-links z-40 bg-[#F5F5DC]
              
             fixed h-full w-full ${
               active ? "left-1/2 -translate-x-1/2 " : "left-full"
             } top-1/2 transform -translate-y-1/2 flex flex-col pb-40 justify-center items-center xl:p-0 xl:bg-transparent xl:flex xl:flex-row xl:justify-normal xl:static xl:top-auto xl:left-auto xl:transform-none xl:w-auto xl:h-auto gap-8 text-xl font-medium`}
          >
            <li
              className="cursor-pointer relative"
              onMouseEnter={() => window.innerWidth >= 768 && setIsOpen(true)}
              // onMouseLeave={() => window.innerWidth >= 768 && setIsOpen(false)}
              ref={menuRef}
            >
              <div
                className="flex items-center gap-2 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
              >
                Qui sommes-nous ?
                <IoIosArrowDown
                  className={`transition-transform ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </div>

              {isOpen && (
                <ul className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-10"
                onMouseEnter={() => window.innerWidth >= 768 && setIsOpen(true)}
                onMouseLeave={() => window.innerWidth >= 768 && setIsOpen(false)}
                >
                  <Link to="/notre-histoire">
                    <li className="text-base px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Histoire
                    </li>
                  </Link>
                  <Link to="/projet-pedagogique">
                    <li className="text-base px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Projet pédagogique
                    </li>
                  </Link>
                </ul>
              )}
            </li>
                {/* <Link to='/activites-pedagogique'>
            <li className="cursor-pointer">Activités pédagogique</li>
                </Link> */}
            <Link to="/evenements">
              <li className="cursor-pointer">Évènements</li>
            </Link>
            <Link to="/modalites-inscription">
              <li className="cursor-pointer">Inscription</li>
            </Link>
            <Link to="/nous-soutenir">
              <li className="cursor-pointer">Nous soutenir</li>
            </Link>
            <Link to="/contact">
              <Button
                className="text-sm bg-vertLogo"
                variant="outline"
                aria-label="bouton contactez nous"
              >
                <span>Contactez-Nous</span>
              </Button>
            </Link>
          </ul>
        </nav>
      </header>
    </>
  );
};
