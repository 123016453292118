import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faLeaf,
  faUsers,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";
import { TimelineComponent } from "../../composants/timeline/Timeline";
import { Reviews } from "../../composants/reviews/Reviews";
import { Faq } from "../../composants/faq/Faq";
import { motion } from "framer-motion";

export const Propos = () => {

  return (
    <>
      <main className="propos w-full h-full">
        <h3 className="text-center text-2xl lg:text-4xl pb-[40px]">
          À propos de nous
        </h3>
        <div className="w-full h-full py-[40px] flex flex-col gap-10">
          <div className=" sm:w-3/4 sm:mx-auto flex justify-center items-center flex-col gap-[20px] xl:flex-row lg:gap-10">
            {/* Chaque item avec animation */}
            <motion.div
              className="flex w-full text-center justify-center items-center "
              initial={{ opacity: 0, y: 20 }} // Commence avec une opacité 0 et un léger décalage vertical
              whileInView={{ opacity: 1, y: 0 }} // Lorsqu'il entre dans la vue, il devient opaque et revient à sa position d'origine
              transition={{ duration: 0.5, delay: 0.1 }} // Transition douce avec délai pour les éléments
              viewport={{ once: true }} // L'animation se déclenche uniquement une fois
            >
              <FontAwesomeIcon icon={faStar} className="text-yellow-500 mr-3" aria-label="Excellence académique"/>
              <span className="text-xl">Excellence académique.</span>
            </motion.div>

            <motion.div
              className="flex w-full text-center justify-center items-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <FontAwesomeIcon icon={faLeaf} className="text-green-500 mr-3" aria-label="Respect de l'environnement"/>
              <span className="text-xl">Respect de l'environnement.</span>
            </motion.div>

            <motion.div
              className="flex w-full text-center justify-center items-center "
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
            >
              <FontAwesomeIcon icon={faUsers} className="text-blue-500 mr-3" aria-label="Communauté engagée."/>
              <span className="text-xl">Communauté engagée.</span>
            </motion.div>

            <motion.div
              className="flex w-full text-center justify-center items-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              viewport={{ once: true }}
            >
              <FontAwesomeIcon
                icon={faPaintBrush}
                className="text-red-500 mr-3"
                aria-label="Créativité et expression."
              />
              <span className="text-xl">Créativité et expression.</span>
            </motion.div>
          </div>

          <section className="flex flex-col justify-evenly items-center py-5 gap-20">
            <TimelineComponent />
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 2 }}
              viewport={{ once: false }} // Animation déclenchée chaque fois que l'élément entre dans la vue
              className="w-3/4 flex flex-col items-center lg:flex-row gap-10 mt-10"
            >
              <div className="lg:w-1/2 h-full flex flex-col items-center gap-10">
                <h4 className="text-3xl font-semibold text-center">
                  FAQ - Foires aux questions
                </h4>
                <p className="mt-3 text-lg text-[#848660] font-medium text-center">
                  Retrouvez les réponses aux questions courantes des parents et
                  découvrez comment nous accompagnons vos enfants !
                </p>
              </div>
              <div className="lg:w-1/2">
                <Faq />
              </div>
            </motion.div>
            <div className="w-full flex flex-col md:flex-wrap md:flex-row justify-evenly gap-2 px-5">
              <Reviews />
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
