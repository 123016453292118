import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaPhoneAlt, FaUsers, FaFileAlt, FaCheckCircle } from "react-icons/fa";
import { TbArrowBadgeRight } from "react-icons/tb";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const ModalitesInscription = () => {
  const steps = [
    {
      title: "Prise de contact",
      description:
        "Premier échange pour découvrir l'école et obtenir des informations par mail.",
      icon: <FaPhoneAlt className="text-2xl text-[#8F9324]" />,
    },
    {
      title: "Rencontre",
      description:
        "Un rendez-vous avec l’équipe pédagogique pour présenter le projet éducatif et passer un entretien.",
      icon: <FaUsers className="text-2xl text-[#8F9324]" />,
    },
    {
      title: "Dossier d'inscription",
      description:
        "Remplissage et soumission du dossier avec les pièces justificatives.",
      icon: <FaFileAlt className="text-2xl text-[#8F9324]" />,
    },
    {
      title: "Validation & Paiement",
      description:
        "Confirmation de l’inscription après validation du dossier et règlement des frais.",
      icon: <FaCheckCircle className="text-2xl text-[#8F9324]" />,
    },
  ];
  const location = useLocation();

  useEffect(() => {
    document.title = "Modalités d'Inscription - École Primaire L'Olivier";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Découvrez les modalités d'inscription à l'école primaire L'Olivier et comment inscrire votre enfant pour la prochaine rentrée."
      );
    } else {
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content =
        "Découvrez les modalités d'inscription à l'école primaire L'Olivier et comment inscrire votre enfant pour la prochaine rentrée.";
      document.head.appendChild(metaTag);
    }
  }, [location]);
  return (
    <section className="w-full max-w-5xl mx-auto py-16 px-6">
      {/* Header */}
      <motion.div
        className="text-center mb-12"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h2 className="text-4xl font-bold text-black mb-2">
          Modalités d'Inscription & Tarifs
        </h2>
        <span className="text-lg text-gray-600">
          Découvrez les étapes d'inscription et les tarifs appliqués.
        </span>
      </motion.div>

      {/* Étapes d'inscription */}
      {/* Étapes d'inscription */}
      <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
        {steps.map((step, index) => (
          <motion.div
            key={index}
            className="p-6 border border-gray-300 rounded-lg shadow-md bg-white flex flex-col items-center text-center"
            variants={fadeIn}
          >
            {step.icon}
            <h3 className="text-xl font-semibold text-[#8F9324] mt-4">
              {step.title}
            </h3>
            <p className="text-gray-600 text-center mt-2 text-sm">
              {step.description}
            </p>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className="p-6 border border-gray-300 rounded-lg shadow-md bg-white flex flex-col items-center text-center w-full mb-16 sm:max-w-xl sm:mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3 className="text-lg font-semibold text-olive">
          Information complémentaire
        </h3>
        <p className="text-sm text-gray-700 mt-2">
          Pour inscrire votre enfant, veuillez envoyer un email avec :
        </p>
        <ul className="text-sm text-gray-700 mt-2 text-left">
          <li className="flex items-center">
            {" > "}
           Lettre de motivation (PDF ou Word)
          </li>
          <li className="flex items-start">
            {" > "}
            Nom, prénom, date de naissance et classe souhaitée de l'enfant
          </li>
          <li className="flex items-center">
            {" > "}
            Noms, prénoms et coordonnées des
            parents/tuteurs
          </li>
        </ul>
        <Link
          to="/contact"
          className="mt-6 px-4 py-2 bg-vertLogo text-white rounded-md hover:bg-olive-dark transition"
        >
          Contacter l'école
        </Link>
      </motion.div>

      {/* Tableau des tarifs */}
      <motion.div
        className="mb-16"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <h3 className="text-2xl font-semibold text-black text-center mb-6">
          Tarifs
        </h3>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-[#8F9324] text-white">
                <th className="p-3 border border-gray-300">Niveau</th>
                <th className="p-3 border border-gray-300">
                  Frais de scolarité
                </th>
                <th className="p-3 border border-gray-300">Cantine</th>
                <th className="p-3 border border-gray-300">
                  Tarif total annuel
                </th>
              </tr>
            </thead>
            <tbody>
              {["Maternelle/Primaire"].map((niveau, index) => (
                <motion.tr
                  key={index}
                  className="text-center"
                  variants={fadeIn}
                >
                  <td className="p-3 border border-gray-300">{niveau}</td>
                  <td className="p-3 border border-gray-300">2350 €</td>
                  <td className="p-3 border border-gray-300">675 €</td>
                  <td className="p-3 border border-gray-300">3025 €</td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>

      {/* Bouton CTA */}
      <motion.div
        className="text-center"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <Link to="/contact">
          <motion.button
            className="px-6 py-3 bg-vertLogo text-white rounded-lg shadow-lg hover:bg-gray-800 transition cursor-pointer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Inscrire mon enfant
          </motion.button>
        </Link>
      </motion.div>
    </section>
  );
};

export default ModalitesInscription;
