import { React, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import img from "/assets/images/1accueil.webp";
import { Spinner } from "@material-tailwind/react";

export function Gallerie() {
  const srcImage = "/assets/images/";
  const [imgCount, setImgCount] = useState(3);
  const [see, setSee] = useState(true);

  const scrollToGalerie = () => {
    // Remplace `next-section-id` par l'ID de la section suivante
    const nextSection = document.getElementById("galerie");
    nextSection?.scrollIntoView({ behavior: "smooth" });
  };

  const data = [
    {
      imageLink: `${img}`,
    },
    {
      imageLink: `${srcImage}2accueil.webp`,
    },
    {
      imageLink: `${srcImage}3accueil.webp`,
    },
    {
      imageLink: `${srcImage}4accueil.webp`,
    },
    {
      imageLink: `${srcImage}5accueil.webp`,
    },
    {
      imageLink: `${srcImage}6accueil.webp`,
    },
  ];

  const [loading, setLoading] = useState(Array(data.length).fill(true));

  const handleImageLoad = (index) => {
    setLoading((prev) =>
      prev.map((loading, i) => (i === index ? false : loading))
    );
  };

  useEffect(() => {
    const updateImgCount = () => {
      const largeScreen = window.matchMedia("(min-width : 960px)").matches;
      if (largeScreen) {
        setImgCount((prev) => (prev > 3 ? prev : data.length));
        setSee(false);
      } else {
        setImgCount((prev) => (prev > 3 ? prev : 3));
        setSee(true);
      }
    };

    updateImgCount();

    window.addEventListener("resize", updateImgCount); // Écoute les changements de taille
    return () => {
      window.removeEventListener("resize", updateImgCount); // Nettoie l'écouteur
    };
  }, []);

  return (
    <>
      <div className=" galerie grid grid-cols-1 gap-5 w-[100%] mx-auto sm:grid-cols-2 md:grid-cols-3 p-10" id="galerie">
        {data.slice(0, imgCount).map(({ imageLink }, index) => (
          <div
            key={index}
            className="hover:scale-105 transition-transform ease-linear relative"
          >
            {loading[index] && (
              <div className="w-full h-full grid place-items-center absolute">
                <Spinner className="h-16 w-16 text-gray-900/50" />
              </div>
            )}

            <img
              className={`h-40 w-full max-w-full object-cover object-center rounded-lg ${
                loading[index] ? "opacity-0" : "opacity-100"
              } transition-opacity duration-500`}
              src={imageLink}
              alt="Galerie de photos de l'École primaire privée l'Olivier Marseille"
              onLoad={() => handleImageLoad(index)}
              loading="lazy"
            />
          </div>
        ))}
      </div>
      {imgCount < data.length ? (
        <div className="flex items-center gap-2 justify-center transition">
          <button
            className="px-4 py-2 bg-[#5D58ED] text-white rounded hover:bg-[#5D58ED] flex gap-3 items-center"
            onClick={() => setImgCount(imgCount + 3)}
          >
            <FontAwesomeIcon icon={faCaretDown} />
            Voir plus
          </button>
        </div>
      ) : (
        see && (
          <div className="flex items-center gap-2 justify-center transition">
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center gap-3"
              onClick={() => {
                setImgCount(3)
                scrollToGalerie()
              }}
            >
            <FontAwesomeIcon icon={faCaretUp} />
              Voir moins
            </button>
          </div>
        )
      )}
    </>
  );
}
