import React from "react";
import logo from "/assets/images/logo-lolivier300.webp";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { motion } from "framer-motion";

export const Home = () => {
  return (
    <>
      <main className="home w-full h-full ">
        <div className="main-home mx-auto w-[70%] pt-20 mb-9 flex flex-col justify-center items-center gap-6">
          <div className="log">
            <img
              src={logo}
              className="w-40 h-40 lg:w-60 lg:h-60"
              alt="Logo de l'école l'Olivier, école primaire musulmane"
              />
          </div>

          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7 }}
          >
            <div className="textHomen flex flex-col items-center gap-6 text-center text-white">
              <h1 className="text-4xl sm:text-5xl">École Primaire l'Olivier</h1>
              <span className="text-xl italic text-center">
                La première Ecole Primaire Privée Musulmane en France{" "}
              </span>
            </div>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.1, rotate: 5 }}
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1, type: "spring", stiffness: 300 }}
          >
            <Link to="/contact">
              <Button
                variant="outline"
                className="bg-vertLogo text-white text-xl font-normal normal-case"
              >
                <span>Rejoignez nous !</span>
              </Button>
            </Link>
          </motion.div>
        </div>
      </main>
    </>
  );
};
